import { createContext, useState } from "react";
import { DateTime } from "luxon";
import { RoleOptions, User } from "../types";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga4";
import Intercom from "@intercom/messenger-js-sdk";
import { makeAPIRequest } from "../api/apiWrapper";
interface GlobalContext {
  user: User | null;
  storeUser: (user: any) => void;
  reloadUser: () => void;
}

export const fetchUsersEmail = async (query: string) => {
  const data = await makeAPIRequest(
    `/user/search-nominations?query=${encodeURIComponent(query)}`,
    "GET"
  );
  return data.map((u: any) => ({
    ...u,
    label: `${u.firstName} ${u.lastName} (${u.title || "No Title"}) <${
      u.email
    }>`,
    value: u.email,
  }));
};

export const isPortfolioCompanyUserOrAdmin = (user: User) => {
  return (
    (user?.role == RoleOptions.Company &&
      user?.company?.cohortMembership?.length > 0) ||
    user.role == RoleOptions.Admin
  );
};

export const isNotBioToolsAlumni = (user: User) => {
  if (
    !user.company.cohortMembership ||
    user.company.cohortMembership.length === 0
  ) {
    return true;
  }

  if (user.company.cohortMembership.some((c) => c.includes("biotools"))) {
    return false;
  }

  return true;
};

export const isNotMedTechAlumni = (user: User) => {
  if (
    !user.company.cohortMembership ||
    user.company.cohortMembership.length === 0
  ) {
    return true;
  }

  if (user.company.cohortMembership.some((c) => c.includes("biotools"))) {
    return true;
  }

  return false;
};

export const isPortfolioCompanyUserOrPartner = (user: User) => {
  return (
    (user?.role == RoleOptions.Company &&
      user?.company?.cohortMembership?.length > 0) ||
    user.role == RoleOptions.Partner
  );
};

export const UserContext = createContext<GlobalContext>({
  user: null,
  storeUser: () => {},
  reloadUser: () => {},
});
export function UserProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = useState({});
  const storeUser = (user: User) => {
    setUser({
      ...user,
    });
    Bugsnag.setUser(
      user.id.toString(),
      user.email,
      `${user.firstName} ${user.lastName}`
    );
    ReactGA.set({ userId: user.id, user_id: user.id });
    // Store the user in local storage for later reloads
    localStorage.setItem("user", JSON.stringify(user));
    // Store an expiration countdown for when to kick them back to the login page
    // We do this as some NextJS pages will load without making an API request (auto redirects to login)
    // And instead when they first hit the site, if we know they won't have api access, just make them log back in now
    localStorage.setItem(
      "backendJwtExpirationDate",
      DateTime.now().plus({ days: 14 }).toISO()
    );
  };

  if (typeof window !== "undefined") {
    const backendJwtExpirationDate = localStorage.getItem(
      "backendJwtExpirationDate"
    );
    if (backendJwtExpirationDate) {
      const expirationDate = DateTime.fromISO(backendJwtExpirationDate);
      if (expirationDate < DateTime.now()) {
        localStorage.removeItem("backendJwtExpirationDate");
        localStorage.removeItem("user");
        // redirect?
      }
    }
    const userFromStorage = localStorage.getItem("user") as string;
    user = JSON.parse(userFromStorage);

    if (user) {
      Intercom({
        app_id: "ic63pa6g",
        user_id: (user as User).id.toString(),
        name: `${(user as User).firstName} ${(user as User).lastName}`,
        email: (user as User).email,
        // created_at: user.created, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
    }
  }

  const reloadUser = async () => {
    const authResponse = await fetch(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/whoami`,
      {
        credentials: "include",
        redirect: "error",
      }
    );

    if (authResponse.status < 400) {
      const refreshedUser = await authResponse.json();
      // If the user object changed, update the context
      if (!user || JSON.stringify(user) !== JSON.stringify(refreshedUser)) {
        storeUser(refreshedUser);
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        user: user as User | null,
        storeUser: storeUser as any,
        reloadUser: reloadUser as any,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
